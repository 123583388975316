import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

import DesignIllustration from "../../images/connected_office.svg";
import ArrowDownIconImage from "../../images/arrow-down-icon.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col-reverse lg:flex-row lg:items-center max-w-screen-xl mx-auto py-10 md:py-12`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:mt-32 lg:text-left`;
const RightColumn = tw.div`relative mb-12 lg:mb-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0 mt-16`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

const GoDownButton = styled.div`
  ${tw`flex justify-center text-center h-full w-full px-2 py-8 mt-20`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`animate-bounce w-6 h-6`}
    }
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ executeScroll }) => {

  return (
    <>
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>
              Technologies &amp; innovations <span tw="text-primary-500">pour tous</span>
            </Heading>
            <Paragraph>
              Modernisez votre activité avec les solutions technologiques qui répondent au mieux à vos besoins.
            </Paragraph>
            <Actions>
              <input type="email" placeholder="Votre Adresse E-mail " />
              <button>Contactez nous</button>
            </Actions>
            <GoDownButton onClick={executeScroll}>
              <span className="imageContainer">
                <img src={ArrowDownIconImage} alt=""/>
              </span>
            </GoDownButton>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img tw="min-w-0 w-10/12 max-w-lg xl:max-w-3xl" src={DesignIllustration} alt="Design Illustration" />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
      </Container>
    </>
  );
};
