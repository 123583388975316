import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/logo.svg";

import ColorSwatchIconImage from "images/color-swatch-icon.svg";
import PurchaseIconImage from "images/shopping-card-icon.svg";
import InstallationIconImage from "images/truck-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import AcademicCapIconImage from "images/academic-cap-icon.svg";
import SupportIconImage from "images/support-icon.svg";


const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
      . > {
        ${tw`text-primary-500`}
      }
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const Badge = styled.span`
  ${tw`absolute bg-primary-500 text-white px-2 rounded mx-2`}
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ cards = null, heading = "Amazing Features", subheading = "Features", description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." }) => {
  /*
   * This components has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    { imageSrc: ColorSwatchIconImage, title: "Etude de projet", description: "Nous choisirons ensembles les technologies les plus adaptés à vos besoins." },
    { imageSrc: PurchaseIconImage, title: "Achat", description: "Nous trouverons les fournisseurs les plus avantageux pour vous." },
    { imageSrc: InstallationIconImage, title: "Installation", description: "Après réception, nous assurons l'installation complète de vos solutions." },
    { imageSrc: CustomizeIconImage, title: "Configuration", description: "Nous adapterons au mieux vos nouvelles solutions à votre environnement." },
    { imageSrc: AcademicCapIconImage, title: "Formation", description: "Nous vous proposons des formations complètes pour maitriser vos solutions.", indice: "Bientôt" },
    { imageSrc: SupportIconImage, title: "Support", description: "Nous continuerons à vous accompagner tout au long de vos activités." }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt=""/>
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Feature title"}</span><Badge>{card.indice}</Badge>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
