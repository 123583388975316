import React from "react"
import "App.css"
import "tailwindcss/dist/base.css"
import AnimationRevealPage from "helpers/AnimationRevealPage"
import Header from "components/headers/light.js";
import Hero from "components/hero/TwoColumnWithInput"
import Features from "components/features/ThreeColWithSideImage"
import Footer from "components/footers/MiniCenteredFooter"

function App() {

  return (
    <AnimationRevealPage disabled>
      <Header />
      <Hero />
      <Features
        subheading={<>Nos Services</>}
        heading={<>Comment allons-nous vous aider ?</>}
        description={<>Nous vous accompagnons dans votre projet en vous permettant d'obtenir le meilleur des nouvelles technologies.</>}
      />
      <Footer />
    </AnimationRevealPage>
  )
}

export default App
